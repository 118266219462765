.VidContainerWrapper {
  // padding: 0.5rem;
  // padding-top: 2.5rem;
  // display: flex;
  // flex-flow: column;
  // height: 100%;
  // max-height: 770px;
  // gap: 1rem;
  
  // padding-left: 2.5rem;
  height: 90vh;
  padding: 1rem;
  padding-top: 2.5rem;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: left;

  .VidContainerWrapper-inner-container {
    overflow: auto;
    overflow-y: hidden;
    max-width: fit-content;
    resize: horizontal;

    &::-webkit-resizer {
      cursor: nesw-resize;
      align-items: left;
      border-left: 1.4em solid transparent;
      border-bottom: 1.4em solid #ea2f5794;
    }
  }
  .vid-container {
    height: 100%;
    width: 100%;
  }
  .VidContainerWrapper-inner-container2 {
    height: 100%;
    width: 100%;
  }

  .Vid-Bottom-Ctrls {
    padding: 1rem;
    align-items: center;
  }
  #loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
