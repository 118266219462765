.JsEditor{
  .OpenWindow{
    cursor: pointer;
  }  
}

.JsEditorAndToolTipContainer {
  position: relative;

  .InfoTooltip{
    position: absolute;
    right: 0;
    top: 0
  }
}

.JsEditorModal{
  .ant-modal-content{
    background-color: var(--blings_teal);
  }
  .ant-modal-footer{
    border: none
  }
}