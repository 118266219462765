.LayerSelector {
  .ant-select-selection-overflow-item > span{
    display: inline-flex;
    overflow: hidden;
    max-width: 100%;
  }
}

.LayerTagRender{
  margin: 0.2rem;
  border-radius: 2rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 17px;
  .ant-tag-close-icon {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
}