.playground-left-sidebar{
  padding: 0.7rem;
  .playground-left-sidebar-inner-container{
    height: 100%;
    width: 100%;

    >.ant-space-item:nth-child(5){ // mods list
      flex: 1;
      overflow: hidden;
    }

    >.ant-space-item:last-of-type{
    }


  }




  .delete-video-part-btn{
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    $size: 1rem;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    //color: white;
    border: none;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .video-parts{

    .vid-part-tags-container {
      max-height: 3.6rem; // 2 lines of tags
      overflow: auto;
    }
    .video-part-tag {
      cursor: pointer;
      margin-bottom: 5px;

      //background-color: ;
    }
  }

}