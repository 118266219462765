.BlingsErrorFallback{
  padding-top: 2rem;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  .Title{
    font-size: 1.2rem;
  }
  .code{
    background-color: var(--blings_teal);
    padding: 1rem;
    display: inline-block;
    border-radius: 4px;
    margin: 0;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }
}
