$header-height: 4rem;

#hubspot-messages-iframe-container {
  z-index: 8 !important;
}
.playground-top-bar {
  height: $header-height;
  z-index: 110;
}

.playground-left-sidebar, .NoProjectSelectedScreen {
  height: calc(100vh - #{$header-height});
  //overflow: hidden;

}

$SchemaEditorsWidth: 26rem;
$SchemaEditorsWidthMin: 16rem;
$SchemaEditorsWidthMax: 30vw;

.playground-left-sidebar{
  .ant-layout-sider-zero-width-trigger{
    top: 8px
  }
}

.VidContainerWrapper{
  &.schemaDrawerOpen{
    margin-right: #{"min(#{$SchemaEditorsWidthMax}, #{$SchemaEditorsWidth})"};
  }
}
.SchemaEditors {
  position: absolute;
  top: $header-height;
  right: 0;
  width: $SchemaEditorsWidth;
  min-width: $SchemaEditorsWidthMin;
  max-width: $SchemaEditorsWidthMax;
  z-index: 100;
  height: calc(100vh - #{$header-height});
  .SchemaEditors-open-button{
    //margin: 1rem;
    margin-top: 0;
    width: 100%;
    button{ width: 100%}
  }
    .tabs{
    height: 100%;
    //height: calc(100vh - #{$header-height});
  }
}