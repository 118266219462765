.InfoTooltip {
  display: inline-block;
  transform: translate(0.1rem, -0.2rem);
  //position: absolute;
  //width: 0;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  //background-color: var(--blings_teal);
}
