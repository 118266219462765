.ChangesAddButtons {
  justify-content: space-around;
  //padding: 0 2.7em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //justify-content: center;
  row-gap: 1.1rem;
  > * {
    //flex-basis: 31%; // 3 in a row
  }
}


.NewModButtonRender {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 75%;
  font-weight: 500;

  .NewModButtonsListButtonParent {

    //background: red;
    margin: 0;
    margin-bottom: 0.3rem;


    .NewModButtonsListButtonParent-btn {

      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: none;
      outline: none;
      height: 2.5rem;
      width: 2.8rem;

      border: 1px solid;
      border-width: 0;
      border-bottom-width: 2px;
      padding-bottom: 0.9rem;
    }
  }
}
.NewModGroupDropdown-overlay{
  min-width: 10rem;
  .ant-dropdown-arrow { }
  .ant-dropdown-menu{
    background: var(--blings_purple);
    border-top: 3px solid white;
    padding-left: 0.3rem;
    padding-right: 0.3rem;

    .ant-dropdown-menu-item{
      padding: 0.1rem 0.5rem;
      .ant-dropdown-menu-title-content{
        font-size: 0.7rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .circle{
          width: 1rem;
          height: 1rem;
          display: inline-block;
          border-radius: 50%;
          margin-right: 0.7rem;
        }
      }


    }
    .ant-dropdown-menu-title-content{
    }
  }
}

