.DynamicValueBtn-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }
}




.ModForm{

  //
  input, select, .ant-select .ant-select-selector, .ant-radio-button-wrapper, textarea,
  .ant-input-group-addon,.ant-input-affix-wrapper,  {
  //  background-color: var(--blings_that_gray);
  //  border-color: var(--blings_teal);
  //  color: #fff;
    font-size: 0.8rem;
  //
  }
  //.ant-input-group-addon{
  //  background-color: var(--blings_teal);
  //}

  font-size: 0.8rem;






  .breakpoint-input{
    width: 4rem;
  }

  .dynamic-value-tab-selector{
    margin: 0.5rem auto;
    background-color: var(--blings_teal);
    padding: 0.2rem;
    border-radius: 0.3rem;
    display: inline-flex;



    .ant-radio-button-wrapper {
      font-size: 0.75rem;
      height: 1.8rem;
      min-width: 1.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.ant-radio-button-wrapper-checked {
        box-shadow: none;
      }

      &:not(.ant-radio-button-wrapper-checked) {
        background-color: var(--blings_teal_80);
        &:hover {
          background: var(--blings_teal_60);
        }
      }
      margin: 0.1rem 0.2rem;
      padding: 0 0.3rem;
      border-radius: 0.2rem;
      border: none;

      outline: none;

      &:focus {
        border: none;
      }
      &::before{
        outline: none;
        background: none;
        &:focus {
          border: none;
        }
      }
    }
  }
}
