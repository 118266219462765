/* The Overlay (background) */
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  /* Stay in place */
  z-index: 110;
  /* Sit on top */
  left: 0;
  top: 0;
  background-color: var(--blings_that_gray);
  /* gray */
  overflow-x: hidden;
  /* Disable horizontal scroll */
  //overflow-y: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;

  .closebtn {
    z-index: 200;
    position: absolute;
    top: 1rem;
    right: 2rem;
    font-size: 2.5rem;
    color: #a9b6c3;
    cursor: pointer;
  }

  .content {
    height: 50vh;
    padding-top: 2.5rem;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    align-items: center;

    .demo-container-wrapper {
      overflow: auto;
      overflow-y: hidden;

      //resize: horizontal;
      &::-webkit-resizer {
        cursor: nesw-resize;
        border-left: 1.4em solid transparent;
        border-bottom: 1.4em solid #ea2f5794;
      }
    }

    .demo-loader-container {
      padding: auto;
    }
  }

  .ui-container {
    background-color: var(--blings_teal);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 1;
    margin-top: auto;

    .ui-in-Panel {
      display: flex;
      flex-direction: column;
      width: 60%;
      gap: 1rem;
      padding: 2rem;

      .ui-scenes {
        display: flex;
        gap: 2rem;

        .ant-select {
          width: 90%;

          .ant-select-selector {
            background: var(--blings_that_gray);
            border: var(--blings_that_gray);
            min-height: 2.5rem;
          }
        }

        .icons {
          color: #a9b6c3;
          font-size: 1.7rem;
          padding: 0.2rem;
        }
      }
    }
  }

  .BlingsPlayer {
    background: none;
  }
}