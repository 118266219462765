.before-init {
    /*background: black;*/
    font-family: sans-serif;
    text-align: center;
    color: rgb(0, 173, 238);
    font-size: 3rem;
    padding-top: 10%;
  }
  .spinner {
    width: 5rem;
    height: 5rem;
    position: relative;
    margin: 100px auto;
  }
  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #f13465;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 3s infinite ease-in-out;
    animation: sk-bounce 3s infinite ease-in-out;
  }
  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }
  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }