#root {
  .playground-top-bar {
    padding-left: 1rem;
    padding-right: 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .ConnectToProject {
        margin-left: 2rem;

        .ant-typography {
          margin-bottom: 0;
          margin-right: 0.4rem;
        }
      }
    }

    .center {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: auto;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .right {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1rem;

      .PublishStatus {
        color: var(--blings_gray) !important;
        line-height: 1rem;
        
        p{
          text-overflow: ellipsis;
          word-break: break-all;
          overflow: hidden;
          // Add after the first line 
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

        }
      }

      .PublishButtons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        min-width: fit-content;
        a {
          color: white;
        }

        text-decoration: underline;

        a:hover {
          color: var(--blings_gray)
        }

        @media (max-width: 610px) {
          display: none;
        }

      }

      .BurgerOverlayMenu {
        //padding: 1rem;
        //padding-top: 1rem;
        padding-bottom: 1rem;

        .ant-btn-primary {
          width: 100%
        }

        &.ant-dropdown-menu {

          &,
          .ant-dropdown-menu-item {
            background: var(--blings_purple);
            color: #b6b6b6;
          }

          .ant-dropdown-menu-item:hover {
            color: white;

            //background: var(--blings_purple);
            .ant-dropdown-menu-item {}
          }

        }
      }
    }



    .playground-top-bar-space-container {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      &:first-child {
        display: flex;
      }
    }

    /* .ant-space-item:last-of-type {
    margin-left: auto;
  } */

    .burger-dropdown {
      cursor: pointer;
    }
  }
}

.view-live-version-popover {
  .ant-popover-arrow-content {
    background-color: var(--blings_teal);
  }

  .ant-popover-inner {
    background-color: var(--blings_teal);

    .ant-popover-title {
      color: white;
      border-bottom: 1px solid var(--blings_that_gray);
    }

    .view-live-version-select {
      min-width: 150px;


      .ant-select-selector {
        background: var(--blings_that_gray);
        border: var(--blings_that_gray);
        margin-right: 1rem;
      }
    }
  }

  select,
  .ant-select .ant-select-selector,
  .ant-select-selection-item-remove {
    color: #fff;

    .anticon {
      color: white;
    }
  }

  .ant-tag,
  .ant-select-multiple .ant-select-selection-item {
    background-color: var(--blings_teal_80);
    border-color: transparent;

    &:hover:not(.selected) {
      background-color: var(--blings_teal_60);
    }

    &.selected {
      //background-color: var(--blings_teal);
      border: 1px solid var(--blings_primary);
    }
  }

  .ant-select-clear {
    background-color: transparent;
  }
}