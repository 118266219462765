
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:wght@500&display=swap');


body {
  //font-family:  'Source Sans Pro', sans-serif;
  //font-family: 'Nunito', sans-serif;
  //font-family: 'Open Sans', sans-serif;
  font-family: 'Inter', sans-serif;
  //letter-spacing: ;
  letter-spacing: 0.04em;
}
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 100vh;
}
.main {
  flex:1;
  overflow: hidden;
}
.main, .NoProjectSelectedScreen{
  background-color: var(--blings_teal);

}

.playground-left-sidebar, .playground-top-bar {

}


.form-collapse{
  width:100%;
}
.form-container {
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.pg-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex-grow: 1;
  order: 1;
  position: fixed;
}

.card {
  width: 500px;
}

//.main{
  $dataSetterHeight: 25vh;

  #vid-container {
    //flex-grow: 2;
    //order: 2;
    //height: (100 - $dataSetterHeight);
    /*position: fixed;*/
    /*width: 500px;*/
  }
  .jsoneditor {
    height: $dataSetterHeight;
  }

//}



.VidContainerWrapper{
  /*max-height: 100vh;*/

}

//.left-side {
//  display: flex;
//  flex-direction: column-reverse;
//  align-items: center;
//}
.Header {
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center
}


.code-box-demo .ant-switch {
  margin-bottom: 8px;
}

[data-theme="compact"] p, p {
  margin: 0;
}

.config-panel {
  max-height: 80vh;
  overflow: scroll;
  padding-bottom: 20px;
}

.SideFormActionButtons {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: #d8d6d6;

  .ant-btn-primary{
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 1rem;
  }
}



.ant-tooltip .ant-tooltip-inner {
  background: var(--blings_purple);
  opacity: 0.9;
}

::selection {
  background-color: rgba(179, 212, 252, 0.56);
  color: #fff;
  text-shadow: none;
  //background: #ea2f5759;
  //background: var(--blings_primary)99;
}
.notFoundContent {
  color: #fff;
  opacity: 0.3;
  min-height: 2rem;
  padding: 1rem;
  text-align: center;
}
#root .playground-left-sidebar, #root .playground-top-bar, .main, #root .NoProjectSelectedScreen {


  h1, h2, h3, h4, h5, h6 {
    //user-select: none;
  }
  h1, h2, h3, h4, h5, h6, div, span:not(.anticon[role="button"]):not([class^="mtk"]) {
    color: #fff;
  }
  .ant-typography.ant-typography-secondary {
    //color: var(--blings_blue);
    color: #fff;
    opacity: 0.5;
  }



  .ant-btn-primary:disabled span {
    color: #4e4e4eba;
  }
  input, select, .ant-select .ant-select-selector,
  //.ant-radio-button-wrapper,
  textarea,
  .ant-input-number,.ant-input-affix-wrapper,
  .ant-input-group-addon, .ant-input-number-handler-wrap  {
    background-color: var(--blings_that_gray);
    border-color: var(--blings_teal);
    color: #fff;
  }
  .ant-input-number-handler  {
    border-color: var(--blings_that_gray);
  }
  .ant-input-group-addon,.ant-input-number-handler-wrap{
    background-color: var(--blings_teal);
  }
  .ant-upload button {
    padding: 0 0.5rem;
    border-color: var(--blings_teal_60);
    background-color: var(--blings_teal);
  }
  .ant-input-suffix .ant-input-clear-icon {
    filter: invert(1);
  }
  .ant-select-clear { background: transparent}
  .ant-select-selection-placeholder, ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    //color: red;
    opacity: 0.5; /* Firefox */
  }

  .ant-tag , .ant-select-multiple .ant-select-selection-item {
    background-color: var(--blings_teal_80);
    border-color: transparent;
    &:hover:not(.selected) {
      background-color: var(--blings_teal_60);
    }
    &.selected {
      //background-color: var(--blings_teal);
      border: 1px solid var(--blings_primary);
    }
  }
  .ant-switch-checked:focus {
    box-shadow: none;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
    background: rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-track {
    //background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--blings_teal_60) ;
    border: 3px solid transparent;
    border-radius: 1.5px;
  }
}


body {
  .ant-select-dropdown, .mod-form-json-tree-select-dropdown, .mod-form-select-dropdown{
    background-color: var(--blings_that_gray);
    color: white;

    .ant-select-item-group{
      color: rgba(255, 255, 255, 0.54);
    }
    .ant-select-item-option {
      color: white;
      .type-icon {
        font-weight: 300;
        img {
          margin-right: 0.3rem;
        }
      }

    }
    .ant-select-item-option {
      background: transparent;
      &:hover {
        background-color: var(--blings_teal_80);
      }
      &.ant-select-item-option-selected {
        background-color: var(--blings_teal);
        color: white;
      }
      &.ant-select-item-option-active {
        background-color: var(--blings_teal_60);
      }
    }
    .ant-empty{
      color: white;
    }
    .ant-select-tree{
      background: transparent;
      color: white;
      .ant-select-tree-node-content-wrapper:hover {
        background-color: var(--blings_teal_80);
        //opacity: 0.6;

      }
      .ant-select-tree-node-selected{
        background-color: var(--blings_teal_60);
      }
    }
  }
}