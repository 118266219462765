.ModsList-container{
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  >.ant-space-item:last-of-type{
    overflow: hidden;
  }
}


.modsList-collapse-container{
  //overflow: auto;
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;

  .ant-collapse-header, .ant-collapse-item {
  }

  &, .ModsList.ant-collapse, .ant-collapse-item, .ant-collapse-header, .modPanelText, .modPanelText2, .ant-collapse-content {
    color: #fff;
  }

  .ModsList-container{
    height: 100%;
    width: 100%;
    >.ant-space-item:last-of-type{
      overflow: hidden;
    }
  }



}
.ModsList{
  background: none;
  border: none;

  overflow-x: hidden;
  width: 100%;

  .modIconTitleWrapper {
    flex: 0 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 2rem;
    text-align: center;
    white-space: nowrap;

    .ant-select-selector {
      max-width: 100%;
    }

    .modTitle {
      font-size: 50%;
      font-weight: bold;
    }

    .modIcon {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      > .anticon {
        height: 60%;

        >svg {
          height: 100%;
        }
      }
    }
  }


  .ant-collapse-content {
    border: none;
  }
  //.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header
  .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {

    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    .ant-collapse-arrow{
      //left: 7px;
      display: none;
    }


  }
  .ant-collapse-header {

    //transition: unset !important;
  }
  .ant-collapse-header {
    border-left-color: transparent !important;
  }
  .ant-collapse-content, .ant-collapse-content-box, .ant-collapse-item-active .ant-collapse-header, .ant-collapse-header:hover {
    border-left-color: inherit !important; // so top level JS can set color
  }
  //.ant-collapse-item {
    .ant-collapse-content-box, .ant-collapse-header{
      border-left: 6px solid;
      padding-left: 10px;
    }

  //}
  .modPanelText, .modPanelText2 {
    font-size: 75%;
    //font-style: italic;
    flex: 1 1 0%;
    margin: 0px;// 0.3rem;f

    overflow: hidden;
    min-width: 4rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .modPanelText {
    margin-right: 1rem;
    font-weight: 200}

  .ModForm {
    .ant-collapse-content > .ant-collapse-content-box > .ant-space-vertical{
      max-width: 100%;
    }
  }
  .ModForm > .ant-collapse-header {
    .modPanelBtns {
      //display: none;
      visibility: hidden;
    }
    &:hover {
      .modPanelBtns {
        visibility: unset;
        //display: flex;
      }
    }
  }
  .ModsListPanelHeader {
    height: 2rem;

    .modPanelBtns {
      //display: none;
      //visibility: hidden;
      position: absolute;
      right: 0.5rem;
      background: var(--blings_teal);
    }

    .modPanelBtns{
      flex-direction: row;
      justify-content: flex-end;



      .modPanelBtn {
        opacity: 0.5;
        &:hover{
          opacity: 1;
          border-color: white;
        }
        padding: 0;
        padding-top: 2px;
        > span > svg {
          //display: flex;
        }
        background: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 3px;
        height: 1.5rem;
        width: 1.5rem;
        font-size:1rem;
      }
    }



  }


  .ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0;
  }

  .ant-collapse-content {
    background: var(--blings_teal_80);
  }
  .ant-collapse-item {
    background: var(--blings_teal);
    border: none;
    margin-bottom: 0.3rem;
    border-radius: unset;

    &:last-child, &:first-child, &:last-child > .ant-collapse-header, &:first-child > .ant-collapse-header{
      border-radius: unset;
    }
  }
  border-radius: unset;
}

.ant-select-dropdown, .ant-select-dropdown .ant-select-item-option-content{
  font-size: 0.7rem;
}