.FrameIndicator{
  min-width: 5rem;
  text-align: right;
  margin-right: 1rem;
  color: white;

  .FrameIndicatorInput{
    margin-right: .5rem;
  }
}
